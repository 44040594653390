import { projectData } from '../../data/projectData'
import { projectAboutData } from '../../data/projectAboutData'

import './Projects.css'

function Projects () {
    return (
        <div>
            <div className='projects hidden lg:block' id='projects'>
                <div className='px-20 py-10 2xl:px-40 2xl:py-20'>
                    <div className='grid grid-cols-2 mb-20 2xl:mb-32'>
                        <div className='col-span-1 w-2/3'>
                            <h1>{projectAboutData.title}</h1>
                            <p>{projectAboutData.content}</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-4 gap-5'>
                        {projectData.map((project) => 
                        <div className='col-span-1 border-r-2' key={project.id}>
                            <div className='grid'>
                                <div>
                                    <h3>{project.name}</h3>
                                    <p className='mr-5'>{project.description}</p>
                                </div>
                                <div>
                                    <p>What I used: </p>
                                    <ul className='list-disc'>
                                    {project.tags.map((tag) => 
                                            <li key={tag}><i>{tag}</i></li>
                                    )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        )}
                        <div className='col-span-1'>
                            <div className='grid'>
                                <div>
                                    <h3>And many more...</h3>
                                    <p>Many more projects are currently under active development!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lg:hidden h-screen'>
                <section id="project-text">
                    <div className='p-5'>
                        <h1>{projectAboutData.title}</h1>
                        <p className='font-medium'>{projectAboutData.content}</p>
                    </div>
                </section>
                <section id='project-data'>
                    <div className='grid grid-cols-1 p-5 gap-5 bg-blue-600'>
                        {projectData.map((project) =>
                            <div className='col-span-1 border-b-4 border-gray-400 p-5 rounded-xl bg-white' key={project.id}>
                                <div>
                                    <h3>{project.name}</h3>
                                    <p className='font-medium'>{project.description}</p>
                                </div>
                                <div>
                                    <p className='font-medium'>What I used:</p>
                                    <ul className='list-disc'>
                                    {project.tags.map((tag) =>
                                        <li className='text-gray-800' key={tag}><i>{tag}</i></li>
                                    )}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Projects