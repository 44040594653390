export const skillsData = [
    'Vue',
    'React',
    'Typescript',
    'Laravel',
    'CSharp',
    'PHP',
    'CSS',
    'HTML',
    'JavaScript',
    'Python',
    'Nginx',
    'NuxtJS',
    'PostgreSQL',
    'MySQL',
    'SQLite',
    'Strapi',
    'Tailwind',
    'Git',
]