
import { FaGithub, FaTwitter } from 'react-icons/fa';
import { AiOutlineArrowDown } from 'react-icons/ai'
import { Button, Avatar } from '@nextui-org/react';
import { landingData } from '../../data/landingData';
import { HashLink } from 'react-router-hash-link';
import BgParticles from '../Vfx/BgParticles/BgParticles'
import './Landing.css'

function Landing() {
    return (
        <div>
            <div className='hidden lg:block'>
                <div className='landing-particles'>
                    <BgParticles />
                    <div className='landing' id='landing'>
                        <div className='container-main'>
                            <div className='container-left'>
                                <div className='container-left-box'>
                                    <div className='container-left-content'>
                                        <Avatar src={landingData.image} css={{ size: "$30"}} color="secondary" bordered className='landing-avatar'/>
                                        <h1>{landingData.name}</h1>
                                        <p className='landing-title'>{landingData.title}</p>
                                        <i className='landing-quote'>"{landingData.quote}"</i>
                                        <p>{landingData.description}</p>
                                        <HashLink smooth to='/#about'>
                                            <Button>
                                                <AiOutlineArrowDown size={28}/>
                                            </Button>
                                        </HashLink>
                                    </div>
                                </div>
                            </div>
                            <div className='container-right'>
                                <div className='container-right-box'>
                                    <h1 className='text-white'>Socials</h1>
                                    <div className='container-right-content grid grid-cols-4'>
                                    <a href={landingData.github} target="_blank" rel='noreferrer'>
                                        <FaGithub aria-label='GitHub' size={56} color="black"/>
                                    </a>
                                    <a href={landingData.twitter} target="_blank" rel='noreferrer'>
                                        <FaTwitter aria-label='Twitter' size={56} color="black"/>
                                    </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='lg:hidden'>
                <div className='landing-m'>
                    <div className='flex items-center justify-center text-center h-full'>
                        <div className='introbox-m'>
                            <div className='flex items-center justify-center'>
                                <Avatar src={landingData.image} css={{ size: "$32"}} color="secondary" bordered/>
                            </div>
                            <h1>{landingData.name}</h1>
                            <div className='flex items-center justify-center space-x-5'>
                                <a href={landingData.github} target="_blank" rel='noreferrer'>
                                        <FaGithub aria-label='GitHub' size={56} color="black"/>
                                </a>
                                <a href={landingData.twitter} target="_blank" rel='noreferrer'>
                                        <FaTwitter aria-label='Twitter' size={56} color="black"/>
                                </a>
                            </div>
                            <p className='landing-title'>{landingData.title}</p>
                            <i className='landing-quote'>"{landingData.quote}"</i>
                            <p>{landingData.description}</p>
                            <div className='flex items-center justify-center'>
                                <HashLink smooth to='/#about-m'>
                                    <Button>
                                        <AiOutlineArrowDown size={28}/>
                                    </Button>
                                </HashLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing