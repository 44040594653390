import coffee from '../assets/svg/about/coffee.svg'
import vscode from '../assets/svg/about/vscode.svg'
import ytmusic from '../assets/svg/about/ytmusic.svg'
import snus from '../assets/img/about/snus.webp'

export const requirementData = [
    {
        id: 1,
        name: "VSCode",
        description: "My IDE/text editor of choice, the extensions makes this editor extremely versatile.",
        img: vscode
    },
    {
        id: 2,
        name: "YTMusic",
        description: "There's nothing like putting on your earphones and playing your favorite playlist.",
        img: ytmusic
    },
    {
        id: 3,
        name: "Coffee",
        description: "The only way to compare this is that it's like fuel for your vehicle.",
        img: coffee
    },
    {
        id: 4,
        name: "Snus",
        description: "Sometimes I regret that I didn't start with crack instead.",
        img: snus
    }
]