import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';

import './App.css';

import { Main } from './views'

function App() {
  return (
    <NextUIProvider>
      <div className="App">
          <Routes>
            <Route path="/" element={<Main />}/>
            <Route path="*" element={<Navigate to="/" replace />}/>
          </Routes>
      </div>
    </NextUIProvider>
  );
}

export default App;
