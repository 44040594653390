import { aboutData } from '../../data/aboutData'
import { skillsData } from '../../data/skillsData'
import { skillsCombine } from '../../utils/skillsCombine'
import { requirementData } from '../../data/requirementData'


import './About.css'

function About () {
    return (
        <div>
            <div className='about min-h-screen max-h-fit hidden lg:block' id='about'>
                <div className='grid grid-cols-2 2xl:px-64 2xl:py-20'>
                    <div className='col-span-1 px-5'>
                        <section>
                            <h1>{aboutData.title}</h1>
                            <p className='text-lg'>{aboutData.contents1}</p>
                            <p className='text-lg'>{aboutData.contents2}</p>
                            <p className='text-lg'>{aboutData.contents3}</p>
                        </section>
                        <section className='2xl:mt-10'>
                            <h2>For best results I need 👇🏼</h2>
                            <div className='grid grid-cols-2 gap-5 mr-48 2xl:mt-10'>
                                {requirementData.map((requirement) => 
                                    <div className='col-span-1 mb-5' key={requirement.id}>
                                        <h3><img src={requirement.img} alt={requirement.name} className='inline mr-4 object-contain h-20 w-20 transition ease-in-out hover:scale-105' /> {requirement.name}</h3>
                                        <div className='border-2 border-solid border-gray-400 rounded-xl p-2 border-opacity-30'>
                                            <i>{requirement.description}</i>
                                        </div>
                                    </div>

                                )}
                            </div>
                        </section>
                    </div>
                    <div className='col-span-1 bg-white rounded-xl bg-opacity-40'>
                        <h1 className='ml-28'>My arsenal</h1>
                        <div className='grid grid-cols-3 px-20 py-5'>
                            {skillsData.map((skill, id) => 
                                <div className='col-span-1 flex mb-10 justify-center' key={id}>
                                    <img src={skillsCombine(skill)} alt={skill} className='object-contain h-20 w-20 transition ease-in-out hover:scale-150' />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='about-m lg:hidden min-h-screen max-h-fit' id='about-m'>
                <section id='about-text'>
                    <div className='p-5 flex items-center justify-center'>
                        <div className='p-5 bg-white rounded-3xl max-w-3xl'>
                            <h1>{aboutData.title}</h1>
                            <p className='font-medium'>{aboutData.contents1}</p>
                            <p className='font-medium'>{aboutData.contents2}</p>
                            <p className='font-medium'>{aboutData.contents3}</p>
                        </div>
                    </div>
                </section>
                <section id='arsenal'>
                    <div className='p-3 flex items-center justify-center'>
                        <div className='p-3 bg-white rounded-xl max-w-2xl'>
                            <h1>My arsenal</h1>
                            <div className='grid grid-cols-6 gap-x-5 gap-y-6 p-3'>
                                {skillsData.map((skill, id) =>
                                    <div className='flex justify-center' key={id}>
                                        <img src={skillsCombine(skill)} alt={skill}></img>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
} 

export default About