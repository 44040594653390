
export const projectData = [
    {
        id: 1,
        name: "HC QDisp",
        description: "A queue-management-system, real-time updates, queue-configuration, public and admin views",
        tags: ['Nuxt JS', 'VueJS', 'ExpressJS', 'TailwindCSS', 'Prisma', 'SQLite'],
        image: "placeholder"
    },
    {
        id: 2,
        name: "Limnet",
        description: "My biggest project to-date, a complete dashboard with support for different modules, so far I've created the following modules: CRM, Backup, Inventory, Employee, Orders/Contracts",
        tags: ['Laravel', 'PHP', 'VueJS', 'TailwindCSS', 'PostgreSQL', 'Strapi'],
        image: "placeholder"
    },
    {
        id: 3,
        name: "SD-Copy",
        description: "A copy program (GUI) that utilizes Windows built-in Robocopy for faster copying, it has some different options for the copy operation",
        tags: ['C#', 'Windows OS', '.NET'],
        image: "placeholder"
    },
    {
        id: 4,
        name: "Portfolio",
        description: "You're looking at it! My portfolio site, where you can find out more about me!",
        tags: ['React', 'Typescript', 'TailwindCSS', 'NextUI']
    },
    {
        id: 5,
        name: "SD-Booking",
        description: "An extensive booking and employee management system",
        tags: ['Laravel', 'VueJS', 'FullCalendar', 'PostgreSQL']
    },
    {
        id: 6,
        name: "Homebase",
        description: "A WIP lightweight 'personal' webapp, created to be self-hosted at home. Includes a suite of tools",
        tags: ['PHP', 'VueJS']
    }
]