import csharp from '../assets/svg/skills/csharp.svg'
import css from '../assets/svg/skills/css.svg'
import git from '../assets/svg/skills/git.svg'
import html from '../assets/svg/skills/html.svg'
import javascript from '../assets/svg/skills/javascript.svg'
import laravel from '../assets/svg/skills/laravel-2.svg'
import mysql from '../assets/svg/skills/mysql.svg'
import nginx from '../assets/svg/skills/nginx.svg'
import nuxtjs from '../assets/svg/skills/nuxtJS.svg'
import php from '../assets/svg/skills/php.svg'
import postgresql from '../assets/svg/skills/postgresql.svg'
import python from '../assets/svg/skills/python.svg'
import react from '../assets/svg/skills/react.svg'
import sqlite from '../assets/svg/skills/sqlite.svg'
import strapi from '../assets/svg/skills/strapi.svg'
import tailwind from '../assets/svg/skills/tailwind.svg'
import typescript from '../assets/svg/skills/typescript.svg'
import vue from '../assets/svg/skills/vue.svg'

export const skillsCombine = (skill: string) => {
    const skillName: string = skill.toLowerCase();

    switch (skillName) {
        case 'csharp':
            return csharp;
        case 'css':
            return css;
        case 'git':
            return git;
        case 'html':
            return html;
        case 'javascript':
            return javascript;
        case 'laravel':
            return laravel;
        case 'mysql':
            return mysql;
        case 'nginx':
            return nginx;
        case 'nuxtjs':
            return nuxtjs;
        case 'php':
            return php;
        case 'postgresql':
            return postgresql;
        case 'python':
            return python;
        case 'react':
            return react;
        case 'sqlite':
            return sqlite;
        case 'strapi':
            return strapi;
        case 'tailwind':
            return tailwind;
        case 'typescript':
            return typescript;
        case 'vue':
            return vue;
        default:
            break;
    }
}
