import React from 'react'
import {
    Landing,
    About,
    Projects
} from '../../components'

function Main() {
    return (
        <div>
            <div>
                <Landing />
                <About />
                <Projects />
            </div>
        </div>
    );
}

export default Main